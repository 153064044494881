<template>
  <click-to-edit
    v-model="scope.row.scale.category"
    placeholder="Выберите категорию цели"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.category')"
    type="select"
    select-filterable
    :select-options="elementCategories"
    select-option-id="id"
    select-option-name="name"
    select-cast-value-to-int
    @input="saveElementPropChange(scope.row, 'scale', {category:$event})"
  >
  </click-to-edit>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";

export default {
  name: 'element-category-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      elementCategories: variables.elementCategories
    }
  },

  methods: {

  }
}

</script>

<style>

</style>
